import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { usePageContext } from "../../../shared/components/layout/pageContextProvider"

const ModifyCookiesButton = () => {
    const { cookieBannerState, setCookieBannerState } = usePageContext()
    const { t } = useTranslation();

    return (
        <button className="modify-cookies-button" onClick={() => cookieBannerState === null || cookieBannerState === 'hidden' ? setCookieBannerState('detail') : ''}>{t("cookies.modify")}</button>
    )
}

export default ModifyCookiesButton