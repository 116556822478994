import React from 'react'
import ModifyCookiesButton from '../modify-cookies-button'
import './index.scss'

const StaticPageContent = ({ content, cookies = false }) => {

    return (
        <div className='static-page-content'>
            <div className='static-page-content-inner' dangerouslySetInnerHTML={{ __html: content }}>
            </div>
            {cookies &&
                <ModifyCookiesButton />
            }
        </div>
    )
}

export default StaticPageContent