import React from 'react'
import './index.scss'

const StaticPageTitle = ({ title }) => {

    return (
        <div className='static-page-title'>
            <h1>
                {title}
            </h1>
        </div>
    )
}

export default StaticPageTitle