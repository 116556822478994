import React from "react";
import { graphql } from "gatsby";
import Layout from "../shared/components/layout";
import StaticPageTitle from "./components/static-page-title";
import StaticPageContent from "./components/static-page-content";

const Legal = ({ data, pageContext }) => {

  const { frontmatter, html } = data.legalData.childMarkdownRemark

  const { route } = pageContext

  const metas = {
    title: frontmatter.metaTitle,
    description: frontmatter.metaDescription
  }

  const title = frontmatter.title

  return (
    <Layout
      metas={metas}
      pageContext={pageContext}
    >
      <div className="wrapper-fluid">
        <div className="grid">
          <StaticPageTitle title={title} />
          <StaticPageContent content={html} cookies={route.id === 'cookies'} />
        </div>
      </div>
    </Layout >
  )
}

export default Legal

export const query = graphql`
  query ($language: String!, $dataPath: String!) {
    legalData: file(relativePath: {regex: $dataPath}) {
      childMarkdownRemark {
        html
        frontmatter {
            metaTitle
            metaDescription
            title
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;